import React from 'react';
import { graphql } from "gatsby";
import Layout from '../components/Layout';
import HeroSection from '../components/sections/heroSection';
import IntegrationsSearch from '../components/shared/IntegrationsSearch';
import SEO from '../components/seo';

export const pageQuery = graphql`
  query integrationPage {
    contentfulPage(slug: {eq: "integration"}) {
          sections {
            __typename
            ... on ContentfulHeroSection {
              ...ContentfulHeroSectionFragment
            }
            ... on ContentfulGallerySection {
                description {
                    description
                  }
                  heading
                  imageOnly
                  backgroundColor
                  button {
                    actionEvent
                    icon
                    iconSize
                    id
                    label
                    style
                    url
                  }
                  galleryItems {
                    image {
                      gatsbyImageData(height:120)
                      file {
                        contentType
                        url
                      }
                      description
                      title
                    }
                    title
                    category
                    description {
                      description
                    }
                    linkUrl
                    imageWidth
                    metadata {
                      tags {
                        id
                        name
                      }
                    }
                  }
            }
          }
          seoMetadata {
            description
            keywords
            ogImage {
              gatsbyImageData
              title
            }
            title
          }
          bannerData {
            bannerImage {
              gatsbyImageData(width:30)
            }
            bannerSlug
            bannerText {
              raw
            }
            label
            link
          }
        }
      }
  `;

const IntegratePageGenerateSection = ({ section }) => {
  const { __typename } = section;
  switch (__typename) {
    case "ContentfulHeroSection":
      return <HeroSection sectionContent={section} />;
    case "ContentfulGallerySection":
      return <IntegrationsSearch sectionContent={section} />; 
    default:
      return <> </>;
  }
};

const Integrations = ({ data }) => {
  const { sections, seoMetadata, bannerData } = data.contentfulPage;
  return (
    <Layout bannerData={bannerData}>
      {seoMetadata && (
        <SEO
          title={seoMetadata.title}
          description={seoMetadata.description}
        />
      )}
      {sections.map((section) => (
        <IntegratePageGenerateSection key={section.id} section={section} />
      ))}
    </Layout>
  );
};

export default Integrations;
