/** @jsx jsx */
import { jsx, Box, Container, Flex, Grid, Heading, Input } from 'theme-ui';
import CardIntegration from './cards/CardIntegration';
import Link from './Link';


const IntegrationsSearch = ({ sectionContent }) => {
  const { galleryItems } = sectionContent;

  //Extracting available categories  const categoryList = [...new Set(galleryItems.map(item => item.category))].sort();
  const categoryList = ['Data source', 'Notification actions', 'Configuration stores','Orchestration', 'Notebooks']
  return (
    <Flex sx={{ padding: "50px 0" }}>
      <Container sx={{padding: ["0", null, null, "20px"]}}>
        <Flex sx={{ justifyContent: 'space-between', flexDirection: ['column', 'row'],  marginBottom: ['40px'] }}>
          <Flex sx={{ flexDirection: ['column', 'row'], marginBottom: ['20px', '0'] }}>
            <Box sx={{ margin: ['0 0 10px 0', null, '0 50px 0 0'] }}>
              <Box>
                  <Flex
                    sx={{
                      '@media screen and (max-width: 1018px)': {
                        flexDirection: "column",
                        order: 2
                      },
                      marginBottom: ['50px'],
                      justifyContent: 'space-between'
                    }}
                  >
                    <Flex
                    sx={{
                      '@media screen and (max-width: 900px)': {
                        maxWidth: "unset",
                        alignItems: 'center',
                      },
                      order: 1,
                      display: "flex",
                      minWidth: "200px",
                      textAlign: 'left',
                      flexDirection: 'column',
                      marginBottom: '20px',
                      padding: '0 10px'
                    }}
                    >
                    <Heading as="h4" marginBottom="18px" sx={{ fontWeight: "600"}}>
                      Categories
                    </Heading>
                    {categoryList.map((item, index) => (
                        <Box value={`category-${index}`}>
                          <Link classnameForAnalytics="grey-link mb-1 db" href={`#category-${index}`} target='_self' >
                            {item}
                          </Link>
                        </Box>
                    ))}
                    </Flex>
                    <Box
                    sx={{
                      maxWidth: "825px",
                      px: "10px",
                      '@media screen and (max-width: 1018px)': {
                        maxWidth: "unset"
                      },
                      paddingTop: [10, 0],
                      order: 2,
                    }}
                    className="blog-content"
                    >
                    <Flex
                      sx={{
                        flexDirection: "column",
                        alignItems: "center",
                        mb: '36px',
                        lineHeight: 'none'
                      }}
                      >
                      {categoryList.map((category, index) => (
                        <Box  sx={{marginBottom:"4em", width: '100%'}}>
                          <Heading as="h3" marginBottom="18px" id={`category-${index}`} sx={{fontWeight:"bold", marginBottom:"1em",padding: ['0 10px', '0']}} >
                            {category}
                          </Heading>
                          {<Grid gap={["10px", 4]} columns={[1, 2, 3]} sx={{px: ["10px", 0, 0]}}>
                            {galleryItems && galleryItems.filter((item)=>{return item.category == category}).map((data) => (
                              <CardIntegration item={data} styling={{padding: ["20px"]}}/>
                            ))}
                          </Grid>}
                        </Box>
                      ))}
                      </Flex>
                    </Box>
                  </Flex>
              </Box>
            </Box>
          </Flex>
        </Flex>
      </Container>

    </Flex>
  );
};

export default IntegrationsSearch;
