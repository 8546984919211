/** @jsx jsx */
import React from "react";
import { jsx, Box, Button, Flex, Heading, Paragraph } from "theme-ui";
import { GatsbyImage } from "gatsby-plugin-image";
import Link from "../Link";
import Action from "../Action";

const CardIntegration = ({ item, imageOnly, styling }) => {
  const { image, title, description, linkUrl, imageWidth, button, metadata, category } = item;
  return (
    <Flex
      sx={{
        flexDirection: !imageOnly ? "column" : "row",
        boxShadow:'0px 0px 30px #00000022',
        borderRadius:'15px',
        backgroundColor:'#fff',
        position: "relative",
        marginBottom: "1em",
        padding:'20px 0',
        width: '100%',
        ...styling
      }}
    >
    <Link to={linkUrl} aria-label={linkUrl && `Link to ${title}`}>

      <Box sx={{ position: "absolute", right: "9px", top: "9px", textTransform: "uppercase", color: "#404041", fontSize: "0.9em"}}>
        { item.category }
      </Box>
      <Box sx={{ height:"60px", minWidth: "206px", overflow: "hidden"}}>
        <GatsbyImage
          image={image?.gatsbyImageData}
          alt={image?.description || 'grid image'}
          objectFit="contain"
          sx={{ width: imageWidth ? imageWidth : imageOnly ? "60px" : "60px", flex: 1, height: "60px" }}
        />
      </Box>
      <Box>
        {metadata.tags.map((tag) => (
          <Button variant="primaryAltV2" sx={{ fontSize: "13px", height: "25px", margin: "1em 5px 1em 0", paddingTop: "2px"}}>
            { tag.name }
          </Button>
        ))}
      </Box>
      {!imageOnly && (
        <Box sx={{ flex: 1 }}>
          {title && (
            <Heading
            as="h4"
            variant="bold"
            sx={{  mb: "8px", fontSize: [3] }}
            >
              {title}
            </Heading>
          )}
          {!imageOnly && description && (
            <Paragraph sx={{ color:'#2b354f' }}>
              {description.description}
            </Paragraph>
          )}
          {button && (
                <Box sx={{
                    display: 'flex',
                    mb: 4, //margin bottom
                    mt: 2, //margin top
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                    className="hero-action-item-container">
                    <Action
                        {...button}
                    />
                </Box>
            )}
        </Box>
      )}
      </Link>
    </Flex>
  );
};

export default CardIntegration;
